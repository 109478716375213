<template>
  <div>
    <button @click="test">TEST</button>
  </div>
</template>

<script>
import { db, fieldValue } from "./../database";

export default {
  mounted() {
    this.$hideLoading();
  },
  methods: {
    test() {
      console.log("starting...");

      // db.doc("test/RaI0ugVPTd4KStgzGv0k")
      //   .update({
      //     items: fieldValue.arrayRemove("great top"),
      //   })
      //   .then(() => {
      //     console.log("done");
      //   });

      // db.doc("test/RaI0ugVPTd4KStgzGv0k")
      //   .update({
      //     items: Array.from({ length: 500 }, (_, i) => "pure dark jeans"),
      //   })
      //   .then(() => {
      //     console.log("done");
      //   });

      db.doc("test/RaI0ugVPTd4KStgzGv0k").get().then(snap => {
        // console.log(snap.data())
        console.log("done")
      })

      // db.collection("test").get().then(snaps => {
      //   snaps.forEach(snap => {
      //     // console.log(snap.data())
      //   })
      //   console.log("done")
      // })
    },
  },
};
</script>

<!-- <template>
  <Slider    
    ref="Slider"
    direction="horizontal"
    :auto="true" 
    :mousewheel-control="true"
    :performance-mode="true"
    :pagination-visible="true"
    :pagination-clickable="true"
    :loop="true"
    :speed="500">
      <div>
        <img src="./../assets/bakemore.png">                                 
      </div>
      <div>
        <img src="./../assets/meal_bg.png">                                 
      </div>
      <div>
        <h3>KEEP SHOPING AT BAKEDOOOR</h3>
      </div>
  </Slider>
</template>

<script>
  import {bus} from './../main'
  import Slider from 'vue-plain-slider'

  export default {
    created(){
      bus.$emit('show_loading', false)
    },
    components: { Slider },
    methods: {
      onSlideChangeStart (currentPage, el) {
        console.log('onSlideChangeStart', currentPage, el);
      },
      onSlideChangeEnd (currentPage, el) {
        console.log('onSlideChangeEnd', currentPage, el);
      }
    }    
  }
</script>

<style scoped>
.slider {
  height: 300px;
}
</style> -->
